import web3 from 'web3';

export default function getQueryParamString(param: string | Array<string> | undefined): string {
  if (Array.isArray(param)) {
    return param.join(',');
  }

  let checksumAddress = param;

  try {
    checksumAddress = web3.utils.toChecksumAddress(checksumAddress || '');
  } catch(e) {
    checksumAddress = param;
  }

  return checksumAddress || '';
}
